import * as actionTypes from "../actions/actions";
const initialState = {
  logged_in: false,
  user: "",
  menus: [],
  insinfo: "",
  doctors: [],
  marketers: [],
  corporates: [],
  tests: [],
  nav_menu: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LOGIN:
      return {
        ...state,
        logged_in: action.logged_in,
        user: action.user,
        menus: action.menus,
        insinfo: action.insinfo,
        doctors: action.doctors,
        marketers: action.marketers,
        corporates: action.corporates,
        tests: action.tests,
      };

    case actionTypes.SET_LOGOUT:
      return {
        ...state,
        logged_in: action.logged_in,
        user: "",
        menus: [],
        insinfo: "",
        doctors: [],
        marketers: [],
        corporates: [],
        tests: [],
        nav_menu: "",
      };

    case actionTypes.SET_NAV_MENU:
      return {
        ...state,
        nav_menu: action.nav_menu,
      };

    case actionTypes.SET_DOCTOR:
      return {
        ...state,
        doctors: [...state.doctors, action.doctor],
      };

    case actionTypes.SET_MARKETER:
      return {
        ...state,
        marketers: [...state.marketers, action.marketer],
      };

    case actionTypes.SET_CORPORATE:
      return {
        ...state,
        corporates: [...state.corporates, action.corporate],
      };

    case actionTypes.SET_USER:
      return {
        ...state,
        user: action.user,
      };

    case actionTypes.SET_INSINFO:
      return {
        ...state,
        insinfo: action.insinfo,
      };

    default:
      return state;
  }
};

export default reducer;
