import CIcon from "@coreui/icons-react";

const initialState = {
  sidebarShow: "responsive",
  navs: [
    {
      _tag: "CSidebarNavItem",
      name: "Dashboard",
      to: "/dashboard",
      icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
      // badge: {
      //   color: "info",
      //   text: "NEW",
      // },
    },
    {
      _tag: "CSidebarNavTitle",
      _children: ["My Menus"],
    },
    {
      _tag: "CSidebarNavItem",
      name: "Admins",
      to: "/users",
      icon: "cil-drop",
    },
    {
      _tag: "CSidebarNavItem",
      name: "Menus",
      to: "/menus",
      icon: "cil-drop",
    },
    {
      _tag: "CSidebarNavItem",
      name: "Permissions",
      to: "/permissions",
      icon: "cil-drop",
    },
    {
      _tag: "CSidebarNavItem",
      name: "Menu Permission",
      to: "/menu/permissions",
      icon: "cil-drop",
    },
    {
      _tag: "CSidebarNavItem",
      name: "Admin Permission",
      to: "/admin/permission",
      icon: "cil-drop",
    },
    {
      _tag: "CSidebarNavItem",
      name: "Catagory",
      to: "/catagories",
      icon: "cil-drop",
    },
    {
      _tag: "CSidebarNavItem",
      name: "Head",
      to: "/heads",
      icon: "cil-drop",
    },
    {
      _tag: "CSidebarNavItem",
      name: "Investigation Create",
      to: "/investigation",
      icon: "cil-drop",
    },
    {
      _tag: "CSidebarNavItem",
      name: "Daily Statement",
      to: "/daily/statement",
      icon: "cil-drop",
    },
    {
      _tag: "CSidebarNavItem",
      name: "Daily Summary",
      to: "/daily/summary",
      icon: "cil-drop",
    },
    {
      _tag: "CSidebarNavItem",
      name: "Daily Test History",
      to: "/daily/test/history",
      icon: "cil-drop",
    },
    {
      _tag: "CSidebarNavItem",
      name: "Investigation Invoices",
      to: "/investigation/invoices",
      icon: "cil-drop",
    },
    {
      _tag: "CSidebarNavItem",
      name: "Investigation Sales",
      to: "/investigation/sales",
      icon: "cil-drop",
    },
    {
      _tag: "CSidebarNavItem",
      name: "Cash Collection Report",
      to: "/cash/collection/report",
      icon: "cil-drop",
    },
    {
      _tag: "CSidebarNavItem",
      name: "Payment/Receive",
      to: "/payment/receive",
      icon: "cil-drop",
    },
    {
      _tag: "CSidebarNavItem",
      name: "Product Store/Use",
      to: "/lab/product",
      icon: "cil-drop",
    },
    {
      _tag: "CSidebarNavTitle",
      _children: ["Theme"],
    },
    {
      _tag: "CSidebarNavItem",
      name: "Colors",
      to: "/theme/colors",
      icon: "cil-drop",
    },
    {
      _tag: "CSidebarNavItem",
      name: "Typography",
      to: "/theme/typography",
      icon: "cil-pencil",
    },
    {
      _tag: "CSidebarNavTitle",
      _children: ["Components"],
    },
    {
      _tag: "CSidebarNavDropdown",
      name: "Base",
      route: "/base",
      icon: "cil-puzzle",
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: "Breadcrumb",
          to: "/base/breadcrumbs",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Cards",
          to: "/base/cards",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Carousel",
          to: "/base/carousels",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Collapse",
          to: "/base/collapses",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Forms",
          to: "/base/forms",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Jumbotron",
          to: "/base/jumbotrons",
        },
        {
          _tag: "CSidebarNavItem",
          name: "List group",
          to: "/base/list-groups",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Navs",
          to: "/base/navs",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Navbars",
          to: "/base/navbars",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Pagination",
          to: "/base/paginations",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Popovers",
          to: "/base/popovers",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Progress",
          to: "/base/progress-bar",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Switches",
          to: "/base/switches",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Tables",
          to: "/base/tables",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Tabs",
          to: "/base/tabs",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Tooltips",
          to: "/base/tooltips",
        },
      ],
    },
    {
      _tag: "CSidebarNavDropdown",
      name: "Buttons",
      route: "/buttons",
      icon: "cil-cursor",
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: "Buttons",
          to: "/buttons/buttons",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Brand buttons",
          to: "/buttons/brand-buttons",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Buttons groups",
          to: "/buttons/button-groups",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Dropdowns",
          to: "/buttons/button-dropdowns",
        },
      ],
    },
    {
      _tag: "CSidebarNavItem",
      name: "Charts",
      to: "/charts",
      icon: "cil-chart-pie",
    },
    {
      _tag: "CSidebarNavDropdown",
      name: "Icons",
      route: "/icons",
      icon: "cil-star",
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: "CoreUI Free",
          to: "/icons/coreui-icons",
          badge: {
            color: "success",
            text: "NEW",
          },
        },
        {
          _tag: "CSidebarNavItem",
          name: "CoreUI Flags",
          to: "/icons/flags",
        },
        {
          _tag: "CSidebarNavItem",
          name: "CoreUI Brands",
          to: "/icons/brands",
        },
      ],
    },
    {
      _tag: "CSidebarNavDropdown",
      name: "Notifications",
      route: "/notifications",
      icon: "cil-bell",
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: "Alerts",
          to: "/notifications/alerts",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Badges",
          to: "/notifications/badges",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Modal",
          to: "/notifications/modals",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Toaster",
          to: "/notifications/toaster",
        },
      ],
    },
    {
      _tag: "CSidebarNavItem",
      name: "Widgets",
      to: "/widgets",
      icon: "cil-calculator",
      badge: {
        color: "info",
        text: "NEW",
      },
    },
    {
      _tag: "CSidebarNavDivider",
    },
    {
      _tag: "CSidebarNavTitle",
      _children: ["Extras"],
    },
    {
      _tag: "CSidebarNavDropdown",
      name: "Pages",
      route: "/pages",
      icon: "cil-star",
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: "Login",
          to: "/login",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Register",
          to: "/register",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Error 404",
          to: "/404",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Error 500",
          to: "/500",
        },
      ],
    },
  ],
};

const configReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
};

export default configReducer;
